/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
/**
 * Styles specific to the Diversity site.
 *
 */
/* =Colors specific to site
----------------------------------------------------------*/
.brand__lockup img {
  width: auto;
  height: 83px; }

/* =Typography Overrides
----------------------------------------------------------*/
blockquote {
  font-size: 32px;
  line-height: 46px; }
  @media screen and (min-width: 768px) {
    blockquote {
      font-size: 32px; } }

blockquote > p {
  line-height: 46px; }

/* =Primary Navigation
----------------------------------------------------------*/
#primary-nav .level-1 {
  font-size: 13px; }
  @media screen and (min-width: 992px) {
    #primary-nav .level-1 {
      width: 20%;
      font-size: 16px; } }
  @media screen and (min-width: 1200px) {
    #primary-nav .level-1 {
      font-size: 16px; } }

#primary-nav .menu-mlid-1603 {
  width: 13%; }
  @media screen and (min-width: 992px) {
    #primary-nav .menu-mlid-1603 {
      width: 13%; } }

#primary-nav .menu-mlid-1709 {
  width: 21%; }
  @media screen and (min-width: 992px) {
    #primary-nav .menu-mlid-1709 {
      width: 26%; } }
  @media screen and (min-width: 1200px) {
    #primary-nav .menu-mlid-1709 {
      width: 26%; } }

#primary-nav .menu-mlid-1714 {
  width: 24%; }
  @media screen and (min-width: 992px) {
    #primary-nav .menu-mlid-1714 {
      width: 22%; } }

#primary-nav .menu-mlid-1618 {
  width: 18%; }
  @media screen and (min-width: 992px) {
    #primary-nav .menu-mlid-1618 {
      width: 16%; } }

#primary-nav .menu-mlid-1620 {
  width: 24%; }
  @media screen and (min-width: 992px) {
    #primary-nav .menu-mlid-1620 {
      width: 22%; } }

/* =Drawer Navigation
----------------------------------------------------------*/
@media screen and (min-width: 768px) {
  #drawer-nav .menu-mlid-1603 .level-2 {
    width: 16.5%; } }

/* =Demographics Panel
----------------------------------------------------------*/
.demographics {
  position: relative;
  min-height: 511px;
  background: url(../../../themes/diversity/images/home-page-canvas.jpg) no-repeat center center;
  background-size: cover; }

.demographics__blur {
  background: url(../../../themes/diversity/images/home-page-canvas.jpg) no-repeat center center;
  position: absolute;
  z-index: 99;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 99;
  -webkit-filter: blur(5px);
  filter: blur(5px); }
  @media screen and (max-width: 767px) {
    .demographics__blur {
      display: none; } }
  @media screen and (min-width: 1280px) {
    .demographics__blur {
      background-size: cover; } }

.demographics__blur--mask {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  @media screen and (max-width: 767px) {
    .demographics__blur--mask {
      display: none; } }

.center--wrap {
  width: 100%;
  padding: 60px 0; }
  @media screen and (max-width: 767px) {
    .center--wrap {
      background: rgba(0, 0, 0, 0.6); } }
  @media screen and (min-width: 768px) {
    .center--wrap {
      padding: 4% 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); } }
  @media screen and (min-width: 992px) {
    .center--wrap {
      padding: 6% 0; } }

@media screen and (min-width: 768px) {
  .stats:before, .stats:after {
    content: " ";
    display: table; }
  .stats:after {
    clear: both; } }

.statistic {
  margin: 0 0 50px;
  text-align: center;
  line-height: 1.1;
  z-index: 100; }
  @media screen and (min-width: 768px) {
    .statistic {
      min-height: 140px;
      margin: 0; } }
  @media screen and (min-width: 992px) {
    .statistic {
      min-height: none;
      margin: 0; } }

.statistic__number {
  color: #fff;
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 45px; }

.statistic__title {
  color: #e7751d;
  color: #f7941d;
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 20px; }

.demographics__cta {
  position: absolute;
  bottom: 30px;
  width: 100%;
  z-index: 100; }
  .demographics__cta .btn--diversity {
    width: 80%;
    font-size: 14px;
    background: rgba(0, 0, 0, 0.5); }
    @media screen and (min-width: 768px) {
      .demographics__cta .btn--diversity {
        width: 60%;
        font-size: 18px; } }
    @media screen and (min-width: 992px) {
      .demographics__cta .btn--diversity {
        width: 45%;
        bottom: 20px; } }

/* =Featured Event
----------------------------------------------------------*/
.featured-event {
  background: #e7751d;
  position: relative;
  overflow: hidden; }

.featured-event .row {
  display: flex;
  align-items: center; }

.featured-event h2 {
  margin: 0;
  padding: 30px 0; }
  .featured-event h2 a {
    color: #FFC72C; }
  .featured-event h2 .external-link:after {
    color: #FFC72C;
    padding: 5px 0 0 10px; }

.featured-event .featured-event__date {
  background: transparent;
  color: #F6A628;
  text-align: center;
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 30px;
  max-width: 100px; }
  @media screen and (min-width: 768px) {
    .featured-event .featured-event__date {
      background: #F6A628;
      color: #e7751d;
      max-width: none;
      padding: 25px 0 99999px 0;
      margin: 0 0 -99999px 0; } }
  @media screen and (min-width: 992px) {
    .featured-event .featured-event__date {
      padding: 15px 0 99999px 0;
      margin: 0 0 -99999px 0; } }
  .featured-event .featured-event__date .days {
    font-size: 50px;
    line-height: 1.5;
    margin-top: -15px; }

/* =Event Teasers
----------------------------------------------------------*/
.event:before, .event:after {
  content: " ";
  display: table; }

.event:after {
  clear: both; }

@media screen and (min-width: 768px) {
  .view-events .teaser__image {
    float: right;
    width: 25%;
    margin: 0; } }

@media screen and (min-width: 768px) {
  .view-events .teaser__detail {
    float: left;
    width: 75%;
    padding: 0 20px 0 0; } }

.event__title {
  margin-bottom: 5px; }

.event__date {
  color: #6f6f6f;
  font-size: 13px;
  font-style: italic;
  line-height: 19px; }

.event__date .date-display-range {
  display: inline; }

.event__date,
.event__description {
  margin-bottom: 20px; }

/* =Landing Page Callouts
----------------------------------------------------------*/
.landing-callout {
  position: relative;
  overflow: hidden; }
  @media screen and (max-width: 767px) {
    .landing-callout {
      max-width: 340px;
      margin: 0 auto; } }
  .landing-callout:after {
    content: '\e80d';
    color: #FFC72C;
    font-size: 30px;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    position: absolute;
    bottom: 10%;
    right: 10%; }

.landing-callout:hover:after {
  color: #cf4520; }

.landing-callout__mask {
  background: rgba(162, 64, 19, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.landing-callout:hover .landing-callout__mask {
  background: rgba(0, 0, 0, 0.55); }

.landing-callout h2 {
  color: #FFC72C; }

.landing-callout:hover h2 {
  color: #cf4520; }

.landing-callout img {
  width: 100%; }

.landing-callout__text {
  position: absolute;
  top: 10%;
  left: 10%;
  right: 10%;
  color: #fff; }
  .landing-callout__text h2 {
    margin: 20px 0; }

/* =Opportunities
----------------------------------------------------------*/
.view-opportunities {
  margin: 20px 0; }

.opportunity {
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid #dddddd; }

.opportunity__title {
  font-size: 20px;
  font-family: "1898Sans-Bold", sans-serif;
  color: #b31b1b;
  line-height: 1.3;
  padding: 0 0 20px; }

/* =Testimonial Teasers
----------------------------------------------------------*/
.testimonial {
  margin: 0 0 40px;
  padding: 0 0 40px;
  border-bottom: 1px solid #dddddd; }
  .testimonial:before, .testimonial:after {
    content: " ";
    display: table; }
  .testimonial:after {
    clear: both; }

.view-testimonials {
  margin-top: 50px; }

@media screen and (min-width: 768px) {
  .view-testimonials .teaser__image {
    float: left;
    width: 25%;
    margin: 0; } }

@media screen and (min-width: 768px) {
  .view-testimonials .teaser__detail {
    float: right;
    width: 75%;
    padding: 0 20px 0 0; } }

.testimonial__attribution {
  color: #6f6f6f;
  font-size: 14px;
  font-style: italic;
  line-height: 19px;
  margin: 10px 0 0; }

.testimonial__readmore {
  margin: 10px 0 0;
  border-width: 0; }

.testimonial .less,
.testimonial .element__toggle--open .more {
  display: none; }

.testimonial .element__toggle--open .less {
  display: inline; }

/* =Dean's Messages / Home
----------------------------------------------------------*/
@media screen and (min-width: 768px) {
  .front .view-messages {
    height: 615px;
    overflow: hidden; } }

@media screen and (min-width: 768px) {
  .message {
    position: relative;
    height: 615px;
    overflow: hidden;
    transition: all .5s; } }

.leader-info {
  padding: 30px 0; }
  @media screen and (min-width: 768px) {
    .leader-info {
      background: #fff; } }

.message.show .leader-info {
  cursor: default; }

.message__photo,
.message__signature {
  width: 315px;
  margin: 0 auto 20px; }

.message__name,
.message__professional-titles {
  width: 280px;
  margin: 0 auto;
  color: #6f6f6f;
  font-size: 13px;
  font-style: italic;
  line-height: 19px; }
  @media screen and (min-width: 992px) {
    .message__name,
    .message__professional-titles {
      width: 380px; } }

.message__professional-titles .item-list ul li {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.quote-info {
  background-color: rgba(0, 0, 0, 0.7);
  font-family: "1898Sans-Regular", sans-serif;
  color: #dddddd;
  font-style: italic;
  line-height: 1.4;
  text-align: center;
  padding: 30px; }
  @media screen and (min-width: 768px) {
    .quote-info {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      font-size: 24px; } }
  @media screen and (min-width: 1200px) {
    .quote-info {
      font-size: 28px; } }

@media screen and (min-width: 768px) {
  .quote-info:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 0 50px 50px;
    border-color: transparent transparent transparent #fff;
    position: absolute;
    top: 237px;
    left: 0; } }

.views-row-first.message .quote-info:after {
  left: auto;
  right: 0;
  border-width: 50px 50px 50px 0;
  border-color: transparent #fff transparent transparent; }

@media screen and (min-width: 768px) {
  .quote-info {
    opacity: 1.0;
    z-index: 100; } }

@media screen and (min-width: 768px) {
  .message__quote-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); } }

.message__quote {
  margin: 0 0 20px; }

@media screen and (min-width: 768px) {
  .views-row-first.message .leader-info {
    float: right; } }

.views-row-first.message .quote-info {
  float: left; }
  @media screen and (min-width: 768px) {
    .views-row-first.message .quote-info {
      left: 0; } }

/* =Dean's Messages / Index
----------------------------------------------------------*/
.message--index {
  margin: 40px 0; }

.message--index__quote {
  font-family: "1898Sans-Regular", sans-serif;
  color: #666666;
  font-size: 22px;
  font-style: italic;
  padding: 0 20px 20px 0; }

.message--index__signature {
  margin: 0 0 20px; }

.message--index__title,
.message--index__professional-titles {
  width: 280px;
  color: #6f6f6f;
  font-size: 13px;
  font-style: italic;
  line-height: 19px; }
  @media screen and (min-width: 992px) {
    .message--index__title,
    .message--index__professional-titles {
      width: 380px; } }

.message--index__professional-titles .item-list ul li {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.message--index__body {
  margin: 20px 0 0; }

.message--index.views-row-last {
  position: relative;
  padding: 60px 0 0; }
  .message--index.views-row-last:before {
    display: block;
    content: "";
    position: absolute;
    left: 25%;
    top: 0;
    height: 1px;
    width: 50%;
    border-bottom: 1px solid #dddddd; }

/* =Custom Callout // Home
----------------------------------------------------------*/
.diversity-callout {
  position: relative;
  background: #FFC72C;
  padding: 20px 0;
  color: #fff; }
  @media screen and (min-width: 768px) {
    .diversity-callout {
      display: flex;
      align-items: center; } }

.diversity-callout__image {
  width: 80%;
  margin: 0 0 20px; }
  @media screen and (min-width: 768px) {
    .diversity-callout__image {
      width: auto;
      margin: 0; } }
  .diversity-callout__image img {
    margin-bottom: 0 !important; }

@media screen and (min-width: 992px) {
  .diversity-callout__description {
    margin: 10% 0 0; } }

.diversity-callout__description h3 {
  margin-top: 0; }

.diversity-callout__description h3 a {
  font-size: 30px;
  color: #fff; }

.diversity-callout__description p {
  margin-bottom: 0; }
  @media screen and (min-width: 992px) {
    .diversity-callout__description p {
      font-size: 20px; } }

/* =Diversity News // Home
----------------------------------------------------------*/
.diversity-news-home {
  background: #cf4520; }

.diversity-news {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #dddddd; }

.diversity-news:last-of-type {
  border: none; }

.diversity-news__title {
  font-size: 20px;
  line-height: 1.3;
  font-family: "1898Sans-Bold", sans-serif;
  color: #b31b1b;
  margin: 0 0 5px; }

.diversity-news-home .diversity-news__title {
  font-size: 20px;
  line-height: 1.3;
  font-family: "1898Sans-Bold", sans-serif;
  color: #fff;
  margin: 0 0 5px; }
  @media screen and (min-width: 768px) {
    .diversity-news-home .diversity-news__title {
      font-size: 28px; } }
  .diversity-news-home .diversity-news__title h3 {
    font-size: 30px; }

.diversity-news-home .diversity-news__description {
  font-size: 16px;
  margin: 0 0 20px 0;
  color: #fff; }
  @media screen and (min-width: 768px) {
    .diversity-news-home .diversity-news__description {
      font-size: 20px;
      margin: 0 0 30px 0; } }

.diversity-news-home .diversity-news__readmore a {
  color: #fff;
  font-size: 16px; }

.diversity-news__text {
  text-align: left;
  padding-top: 20px; }

/* =Spotlight // Home
----------------------------------------------------------*/
.front .view-testimonials {
  margin-top: 0; }

.front .spotlight,
.front .spotlight-testimonial {
  position: relative;
  background: url(/sites/all/themes/diversity/images/home-diversity-testimonials.jpg);
  background-size: cover;
  padding: 60px 0; }
  .front .spotlight:before, .front .spotlight:after,
  .front .spotlight-testimonial:before,
  .front .spotlight-testimonial:after {
    content: " ";
    display: table; }
  .front .spotlight:after,
  .front .spotlight-testimonial:after {
    clear: both; }

@media screen and (min-width: 768px) {
  .spotlight__content {
    display: flex;
    align-items: center; } }

.spotlight__description {
  color: #fff;
  font-size: 13px;
  font-style: italic;
  line-height: 19px; }
  @media screen and (min-width: 768px) {
    .spotlight__description {
      float: right;
      font-size: 14px;
      text-align: right; } }

.spotlight-testimonial .spotlight__description {
  position: static;
  margin: 0 auto;
  padding: 20px 0; }
  @media screen and (min-width: 768px) {
    .spotlight-testimonial .spotlight__description {
      background: none;
      padding: 0;
      margin: 0 0 0 auto; } }

.spotlight-testimonial .spotlight__photo img {
  display: none; }
  @media screen and (min-width: 768px) {
    .spotlight-testimonial .spotlight__photo img {
      display: block; } }

.spotlight-testimonial .spotlight__headshot {
  max-width: 200px;
  height: auto;
  margin: 0 auto 10px; }
  @media screen and (min-width: 768px) {
    .spotlight-testimonial .spotlight__headshot {
      margin: 0 0 10px;
      float: right; } }
  @media screen and (min-width: 1200px) {
    .spotlight-testimonial .spotlight__headshot {
      max-width: none; } }

.spotlight-testimonial .spotlight__name,
.spotlight-testimonial .spotlight__year-or-major {
  text-align: center; }
  @media screen and (min-width: 768px) {
    .spotlight-testimonial .spotlight__name,
    .spotlight-testimonial .spotlight__year-or-major {
      clear: right;
      float: right;
      text-align: right; } }

.spotlight__action {
  padding: 20px;
  text-align: center; }
  @media screen and (min-width: 768px) {
    .spotlight__action {
      float: left;
      background-color: transparent;
      text-align: left;
      padding: 0; } }

.spotlight__quote {
  max-width: 500px;
  font-family: "1898Sans-Regular", sans-serif;
  color: #fff;
  font-style: italic;
  font-size: 18px;
  line-height: 1.4;
  margin: 0 auto; }
  @media screen and (min-width: 768px) {
    .spotlight__quote {
      font-size: 24px;
      padding: 0; } }
  @media screen and (min-width: 992px) {
    .spotlight__quote {
      font-size: 30px;
      line-height: 1.4; } }

.spotlight__action .btn--diversity {
  margin: 20px auto 20px; }
  @media screen and (min-width: 768px) {
    .spotlight__action .btn--diversity {
      margin: 30px 0; } }

/* =Spotlight // Index
----------------------------------------------------------*/
.spotlight--index__photo {
  margin: 0 0 20px; }

.spotlight--index__description {
  margin: 20px 0 0; }

.spotlight--index__year-or-major,
.spotlight--index__school-or-position {
  font-family: "1898Sans-Bold", sans-serif;
  color: #b31b1b;
  font-size: 16px;
  line-height: 1.4;
  margin: 0 0 10px; }

.spotlight--index__quote {
  font-family: "1898Sans-Regular", sans-serif;
  color: #666666;
  font-size: 18px;
  font-style: italic;
  line-height: 1.4;
  margin: 20px 0 0;
  padding: 20px 0 0;
  border-top: 1px solid #FFC72C; }

/* =Staff Directory
----------------------------------------------------------*/
.view-profiles .gridcol {
  margin: 0 0 20px; }

.staff-profile__photo {
  max-height: 192px;
  overflow: hidden;
  margin: 0 0 10px; }

.staff-profile__professional-title,
.staff-profile__phone {
  color: #6f6f6f;
  font-size: 14px;
  font-style: italic;
  line-height: 19px; }

.staff-member__name {
  color: #b31b1b;
  font-family: "1898Sans-Bold", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  padding: 0 0 5px; }

.staff-profile__professional-title .item-list ul,
.staff-profile__professional-title .item-list ul li {
  list-style-type: none;
  margin: 0;
  padding: 0 0 5px; }

.staff-profile__phone {
  font-style: normal;
  margin: 0 0 10px; }

.staff-profile__links ul li {
  list-style-type: none;
  margin: 0;
  padding: 0 0 5px; }

/* =FAQ
----------------------------------------------------------*/
.faq--featured {
  background: #555555;
  margin: 0 0 30px;
  padding: 30px; }

.faq--featured__question {
  font-family: "1898Sans-Bold", sans-serif;
  color: #FFC72C;
  font-size: 24px;
  line-height: 1.5; }

.faq--featured__answer {
  color: #fff;
  font-size: 22px;
  line-height: 1.6; }
  .faq--featured__answer span {
    font-family: "1898Sans-Bold", sans-serif;
    color: #FFC72C; }

.view-faq .view-filters {
  margin: 0 0 30px; }

/* =Communities
----------------------------------------------------------*/
.community__title.element__toggle {
  font-size: 20px;
  line-height: 28px;
  padding: 20px 55px 20px 10px; }
  .community__title.element__toggle:after {
    top: 20px; }

/* =Workshop Participants
----------------------------------------------------------*/
.workshop-participants h3 {
  margin-bottom: 10px; }

.workshop-participants p {
  column-count: 2; }

/* =Child Nav Style
(usually shown on First-Level Pages with Children)
----------------------------------------------------------*/
.pane-menu-block-active-third-level-nav {
  display: none; }
  @media screen and (min-width: 768px) {
    .pane-menu-block-active-third-level-nav {
      display: block;
      margin: 20px 0; } }

.pane-menu-block-active-third-level-nav ul {
  margin: 0;
  padding: 0; }

.pane-menu-block-active-third-level-nav li {
  list-style: none; }

.pane-menu-block-active-third-level-nav a {
  display: block;
  position: relative;
  background-color: #fff;
  color: #cf4520;
  border-bottom: 1px solid #dddddd;
  font-size: 13px;
  line-height: 50px;
  text-align: right;
  padding: 0 60px 0 0;
  -webkit-transition: background-color 0.25s;
  -o-transition: background-color 0.25s;
  transition: background-color 0.25s; }
  .pane-menu-block-active-third-level-nav a:after {
    content: '\e802';
    position: absolute;
    right: 10px;
    top: 5px;
    width: 40px;
    height: 40px;
    color: #dddddd;
    border: 1px solid #dddddd;
    border-radius: 50%;
    font-size: 17px;
    text-align: center;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    line-height: 42px;
    -webkit-transition: background-color 0.25s;
    -o-transition: background-color 0.25s;
    transition: background-color 0.25s; }
  .pane-menu-block-active-third-level-nav a:hover {
    color: #e7751d;
    background-color: #f7f7f7;
    text-decoration: none;
    border-width: 0 0 1px; }
  .pane-menu-block-active-third-level-nav a:hover:after {
    border: 1px solid #e7751d;
    color: #e7751d; }

/* =Forms/Buttons
----------------------------------------------------------*/
.btn--diversity {
  border-color: #FFC72C;
  color: #FFC72C;
  font-style: normal; }
  .btn--diversity:hover, .btn--diversity:focus, .btn--diversity.focus, .btn--diversity:active, .btn--diversity.active,
  .open > .btn--diversity.dropdown-toggle {
    color: #fff;
    background-color: #e7751d;
    border-color: #FFC72C; }

.category .btn--wcm {
  margin: 0; }

.view-filters .form-select {
  max-width: none;
  min-width: 200px; }
  @media screen and (min-width: 768px) {
    .view-filters .form-select {
      min-width: 300px; } }

/* =Footer
----------------------------------------------------------*/
.front footer {
  padding-top: 0; }

@media screen and (min-width: 992px) {
  .footer-nav {
    margin: 0 0 20px; } }

@media screen and (min-width: 992px) {
  .footer-nav .level-1 {
    width: 20%;
    float: left;
    padding: 0 10px 5px; } }

/* =Misc
----------------------------------------------------------*/
.category {
  margin: 0 0 30px; }

.row.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0; }

/* =News Story
----------------------------------------------------------*/
/* =News Story Nodes
----------------------------------------------------------*/
.pane-node-field-story-deck,
.pane-node-field-photo-story-deck {
  font-size: 20px;
  color: #666666;
  line-height: 1.3;
  padding-bottom: 10px; }

.pane-node-field-story-post-date,
.related-news h3 {
  text-transform: uppercase;
  color: #666666;
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 13px;
  letter-spacing: 2px;
  padding-bottom: 10px; }

.pane-node-field-story-featured-image {
  margin: 25px 0; }

.related-news {
  margin: 40px 0;
  padding: 10px 20px 20px;
  border: 1px solid #e7751d; }

/* =News Story Index
----------------------------------------------------------*/
.pane-news-stories-panel-pane-1 h3 {
  margin-top: 0; }

.select2-results__option:first-child {
  font-style: italic;
  border-bottom: 2px solid #ddd; }

.news-story {
  max-width: 640px;
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #dddddd; }
  @media screen and (min-width: 992px) {
    .news-story {
      max-width: none; } }

@media screen and (min-width: 992px) {
  .news-story:last-child {
    border-bottom: none; } }

@media screen and (min-width: 992px) {
  .news-story__title {
    margin-top: 0; } }

.news-story__date {
  text-transform: uppercase;
  color: #666666;
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 13px;
  letter-spacing: 2px;
  margin: 5px 0 20px; }

.news-story__read-more {
  padding-top: 7.5px; }
  .news-story__read-more a:after {
    content: '\e80d';
    color: #e7751d;
    padding: 5px 0 0 10px;
    font-size: 60%;
    vertical-align: top;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    text-decoration: none;
    line-height: 1.5; }

.news-story__tags,
.story-tags {
  margin: 34px 0 0;
  font-size: 12px;
  background: url(../images/tag.png) left center no-repeat;
  background-size: 15px 15px;
  padding-left: 22px; }

.news-story__type {
  font-size: 12px;
  text-transform: uppercase;
  font-family: "1898Sans-Bold", sans-serif;
  padding: 5px 10px 0;
  margin-bottom: 3px;
  background: #cf4520;
  max-width: 640px; }
  .news-story__type a {
    color: #fff; }
    .news-story__type a:hover {
      color: #fff;
      text-decoration: none; }

.news-story__type--1 {
  background: #e7751d; }

.news-story__type--4 {
  background: #ffc72c; }

/* =Index Filters
----------------------------------------------------------*/
.view-news-stories .views-exposed-form {
  margin: 10px 0 60px; }

.view-news-stories .views-exposed-form label {
  display: none; }

/* =Featured News Stories Tiles
----------------------------------------------------------*/
.featured-news-stories {
  margin: 0 0 10px; }
  .featured-news-stories:before, .featured-news-stories:after {
    content: " ";
    display: table; }
  .featured-news-stories:after {
    clear: both; }

.featured-news-story {
  overflow: hidden;
  position: relative;
  max-width: 640px;
  margin: 0 0 3%;
  padding: 0; }
  .featured-news-story a {
    color: #fff; }
  @media screen and (min-width: 768px) {
    .featured-news-story:hover .featured-news-story__image img {
      transform: scale(1.25); }
    .featured-news-story:hover .featured-news-story__teaser {
      display: block; } }
  @media screen and (min-width: 992px) {
    .featured-news-story {
      max-width: none;
      width: 48%;
      float: left;
      margin-right: 2px;
      margin-left: 20px; } }
  @media screen and (min-width: 768px) {
    .featured-news-story {
      width: 47%;
      max-width: none;
      float: left;
      margin-left: 20px; } }

@media screen and (min-width: 992px) {
  .featured-news-story.views-row-odd {
    margin-right: 3%; } }

.mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 97%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25); }

.featured-news-story__image {
  overflow: hidden;
  width: 97%; }

.featured-news-story__image img {
  transition: all 0.3s ease-in-out; }

.featured-news-story__title {
  color: #fff; }

.featured-news-story .news-story__date {
  color: #fff; }

.featured-news-story .story-details {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0 30px;
  z-index: 100; }

.featured-news-story__teaser {
  display: none;
  width: 88%; }

.diversity-news__description {
  height: auto;
  min-height: 200px; }

.featured-image-index {
  float: left;
  padding-right: 20px; }

.latest-diversity-news-item {
  text-align: center; }

.latest-diversity-news-item a {
  color: #ffffff; }

/* ------- Calendar Styles ----------- */
.date-prev {
  margin-top: 24px !important;
  margin-right: 23px !important;
  background: none !important; }

.date-next {
  margin-top: 24px !important;
  background: none !important; }

/* ----- CTools ------ */
.ctools-collapsible-processed .ctools-collapsible-handle h2 {
  position: relative;
  font-size: 18px;
  line-height: 20px;
  color: #cf4520;
  border: 1px solid #ddd;
  border-width: 3px 0 0;
  cursor: pointer;
  margin: 0;
  padding: 15px 55px 15px 10px;
  font-weight: 600; }

.ctools-collapsible-processed .ctools-collapsible-handle h2:after {
  content: '-';
  width: 50px;
  display: block;
  position: absolute;
  top: 15px;
  right: 0;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  border-left: 3px solid #ddd; }

.ctools-collapsible-processed.ctools-collapsed .ctools-collapsible-handle h2:after {
  content: '+'; }

.ctools-collapsible-container .ctools-toggle {
  display: none; }

.fancybox-nav {
  width: 10%;
  height: 50%;
  top: 25%; }

a.fancybox-nav {
  border: 0; }

/**
 * Styles related to Photo Stories
 *
 */
.pane-gallery-panel-pane-2 .views-row {
  margin-bottom: 30px; }
  .pane-gallery-panel-pane-2 .views-row.views-row-odd {
    clear: left; }

.photo-story {
  position: relative; }

.pane-node-field-gallery-videos, .pane-node-field-gallery-images {
  margin-top: 30px; }
  .pane-node-field-gallery-videos .container, .pane-node-field-gallery-images .container {
    position: relative; }
    .pane-node-field-gallery-videos .container .cycle-slide, .pane-node-field-gallery-images .container .cycle-slide {
      width: 100%; }
      .pane-node-field-gallery-videos .container .cycle-slide img, .pane-node-field-gallery-images .container .cycle-slide img {
        margin: 0 auto;
        display: block; }
      .pane-node-field-gallery-videos .container .cycle-slide .galpag a > img, .pane-node-field-gallery-images .container .cycle-slide .galpag a > img {
        height: auto !important; }

.pane-node-field-gallery-images, .paragraphs-items-field-gallery-videos {
  clear: both;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 30px;
  padding-bottom: 30px; }

.paragraphs-items-field-gallery-images .cycle-slideshow, .paragraphs-items-field-gallery-videos .cycle-slideshow {
  background: none; }
  .paragraphs-items-field-gallery-images .cycle-slideshow .paragraphs-item-gallery-images, .paragraphs-items-field-gallery-images .cycle-slideshow .paragraphs-item-gallery-videos, .paragraphs-items-field-gallery-videos .cycle-slideshow .paragraphs-item-gallery-images, .paragraphs-items-field-gallery-videos .cycle-slideshow .paragraphs-item-gallery-videos {
    width: 100%; }

.paragraphs-items-field-gallery-images .thumbnail-controller:before, .paragraphs-items-field-gallery-images .thumbnail-controller:after, .paragraphs-items-field-gallery-videos .thumbnail-controller:before, .paragraphs-items-field-gallery-videos .thumbnail-controller:after {
  content: " ";
  display: table; }

.paragraphs-items-field-gallery-images .thumbnail-controller:after, .paragraphs-items-field-gallery-videos .thumbnail-controller:after {
  clear: both; }

/*.paragraphs-items-field-gallery-images #thumbnail-pager a, .paragraphs-items-field-gallery-videos #thumbnail-pager-video a {
  display: inline-block;
  margin: 0 15px 10px 0;

  &:last-child {
    margin-right: 0;
    padding-right: 0;
  }
}*/
.photo-gallery__image {
  position: relative;
  text-align: center; }
  .photo-gallery__image img {
    margin-bottom: 0 !important; }

.photo-gallery__caption {
  margin: 20px 0; }

p.galcapt {
  text-align: center;
  margin: 20px 0; }

.thumbnail-controller {
  background: #f7f7f7;
  padding: 30px; }
  .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner {
    display: flex;
    justify-content: center;
    flex-direction: row; }
    @media screen and (min-width: 992px) {
      .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner {
        justify-content: space-between; } }
    .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner .thumbnail-controller__pager:after {
      content: '\e802';
      color: #e7751d;
      font-size: 24px;
      vertical-align: middle;
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      /* opacity: .8; */
      /* For safety - reset parent styles, that can break glyph codes*/
      font-variant: normal;
      text-transform: none;
      /* fix buttons height, for twitter bootstrap */
      line-height: 1em;
      /* Animation center compensation - margins should be symmetric */
      /* remove if not needed */
      /* you can be more comfortable with increased icons size */
      /* font-size: 120%; */
      /* Font smoothing. That was taken from TWBS */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      /* Uncomment for 3D effect */
      /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
      -ms-transform: translateY(-50%);
      /* IE 9 */
      transform: translateY(-50%);
      /* IE 10, Fx 16+, Op 12.1+ */
      text-decoration: none;
      line-height: 1.5; }
    .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner .thumbnail-controller__pager.thumbnail-previous, .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner .thumbnail-controller__pager.thumbnail-previous-video {
      flex: 0 1; }
      .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner .thumbnail-controller__pager.thumbnail-previous:after, .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner .thumbnail-controller__pager.thumbnail-previous-video:after {
        content: '\e810'; }
        @media screen and (min-width: 992px) {
          .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner .thumbnail-controller__pager.thumbnail-previous:after, .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner .thumbnail-controller__pager.thumbnail-previous-video:after {
            padding: 3px 10px 0 0; } }
    .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner .thumbnail-controller__pager.thumbnail-next, .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner .thumbnail-controller__pager.thumbnail-next-video {
      flex: 0 1; }
      .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner .thumbnail-controller__pager.thumbnail-next:after, .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner .thumbnail-controller__pager.thumbnail-next-video:after {
        content: '\e802'; }
        @media screen and (min-width: 992px) {
          .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner .thumbnail-controller__pager.thumbnail-next:after, .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner .thumbnail-controller__pager.thumbnail-next-video:after {
            padding: 3px 0 0 10px; } }
    .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner #thumbnail-pager, .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner #thumbnail-pager-video {
      flex: 1 0;
      display: none; }
      @media screen and (min-width: 992px) {
        .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner #thumbnail-pager, .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner #thumbnail-pager-video {
          display: block; } }
      .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner #thumbnail-pager .cycle-slide, .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner #thumbnail-pager-video .cycle-slide {
        vertical-align: top; }
        .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner #thumbnail-pager .cycle-slide span, .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner #thumbnail-pager-video .cycle-slide span {
          display: inline-block; }
          @media screen and (min-width: 992px) {
            .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner #thumbnail-pager .cycle-slide span, .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner #thumbnail-pager-video .cycle-slide span {
              margin: 0 15px 0 0; } }
        .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner #thumbnail-pager .cycle-slide img, .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner #thumbnail-pager-video .cycle-slide img {
          cursor: pointer;
          width: 100%;
          height: auto;
          display: block; }
        .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner #thumbnail-pager .cycle-slide.cycle-slide-active img, .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner #thumbnail-pager-video .cycle-slide.cycle-slide-active img {
          border: 1px solid #b31b1b; }
    .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner #mobile-slide-counter, .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner #mobile-slide-counter-video {
      flex: 0 1 30%;
      text-align: center;
      padding: 5px 20px; }
      @media screen and (min-width: 992px) {
        .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner #mobile-slide-counter, .thumbnail-controller .thumbnail-controller__wrap .thumbnail-controller__wrap-inner #mobile-slide-counter-video {
          display: none !important; } }

/*.thumbnail-controller__wrap {
  float: left;
  position: relative;
  left: 50%;
}

.thumbnail-controller__wrap-inner {
  position: relative;
  left: -50%;
}*/
/*.thumbnail-controller__pager {
  display: block;
  float: left;
  padding: 0 10px;
  &:after {
    content: '\e802';
    color: $wcm-bright-orange;
    font-size: 24px;
    vertical-align: top;
    @include fontello();
    text-decoration: none;
    line-height: 1.5;
  }
}*/
/*
.thumbnail-controller__pager.previous, .thumbnail-controller__pager.previous-video {
  &:after {
    content: '\e810';
    @include breakpoint($md) {
      padding: 3px 10px 0 0;
    }
  }
}

.thumbnail-controller__pager.next, .thumbnail-controller__pager.next-video {
  float: right;
  &:after {
    content: '\e802';
    @include breakpoint($md) {
      padding: 3px 0 0 10px;
    }
  }
}

#thumbnail-pager, #thumbnail-pager-video {
  display: none;
  @include breakpoint($md) {
    float: left;
    display: block;
  }
}

#thumbnail-pager-video a {
  border: none;
}
#thumbnail-pager-video a > img {
  border: 3px solid $wcm-border-gray;
  opacity: .5;
}

#thumbnail-pager-video a > img:hover, #thumbnail-pager-video .cycle-pager-active > img {
  border: 3px solid $wcm-bright-orange;
  opacity: 1;
}*/
/*#mobile-slide-counter, #mobile-slide-counter-video {
  float: left;
  padding: 3px 20px;
  @include breakpoint($md) {
    display: none !important;
  }
}*/
/* =Previous / Next Arrow Overlays
----------------------------------------------------------------------------------*/
.paragraphs-items-field-gallery-images .cycle-prev, .paragraphs-items-field-gallery-images .cycle-next, .paragraphs-items-field-gallery-videos .cycle-prev, .paragraphs-items-field-gallery-videos .cycle-next {
  display: none; }
  @media screen and (min-width: 768px) {
    .paragraphs-items-field-gallery-images .cycle-prev, .paragraphs-items-field-gallery-images .cycle-next, .paragraphs-items-field-gallery-videos .cycle-prev, .paragraphs-items-field-gallery-videos .cycle-next {
      position: absolute;
      top: 0;
      opacity: 0;
      filter: alpha(opacity=0);
      width: 10%;
      z-index: 1002;
      height: 100%;
      cursor: pointer; } }
  @media screen and (min-width: 992px) {
    .paragraphs-items-field-gallery-images .cycle-prev, .paragraphs-items-field-gallery-images .cycle-next, .paragraphs-items-field-gallery-videos .cycle-prev, .paragraphs-items-field-gallery-videos .cycle-next {
      display: block; } }

.paragraphs-items-field-gallery-images .cycle-prev, .paragraphs-items-field-gallery-videos .cycle-prev {
  left: 20px;
  background: url("../images/carousel-prev.png") 35% 50% no-repeat; }
  @media screen and (min-width: 1200px) {
    .paragraphs-items-field-gallery-images .cycle-prev, .paragraphs-items-field-gallery-videos .cycle-prev {
      background-position: 35% 50%; } }

.paragraphs-items-field-gallery-images .cycle-next, .paragraphs-items-field-gallery-videos .cycle-next {
  right: 20px;
  background: url("../images/carousel-next.png") 65% 50% no-repeat; }
  @media screen and (min-width: 1200px) {
    .paragraphs-items-field-gallery-images .cycle-next, .paragraphs-items-field-gallery-videos .cycle-next {
      background-position: 35% 50%; } }

.paragraphs-items-field-gallery-images .cycle-prev:hover,
.paragraphs-items-field-gallery-images .cycle-next:hover, .paragraphs-items-field-gallery-videos .cycle-prev:hover,
.paragraphs-items-field-gallery-videos .cycle-next:hover {
  opacity: .7;
  filter: alpha(opacity=70); }
