/**
 * Styles specific to the Diversity site.
 *
 */


/* =Colors specific to site
----------------------------------------------------------*/

$wcm-diversity-yellow: #FFC72C;
.brand__lockup img {
    width: auto;
    height: 83px;
}


/* =Typography Overrides
----------------------------------------------------------*/

blockquote {
    font-size: 32px;
    line-height: 46px;
    @include breakpoint($sm) {
        font-size: 32px;
    }
}

blockquote>p {
    line-height: 46px;
}


/* =Primary Navigation
----------------------------------------------------------*/

#primary-nav .level-1 {
    font-size: 13px;
    @include breakpoint($md) {
        width: 20%;
        font-size: 16px;
    }
    @include breakpoint($lg) {
        font-size: 16px;
    }
}

// About Us
#primary-nav .menu-mlid-1603 {
    width: 13%;
    @include breakpoint($md) {
        width: 13%;
    }
}

// Current Students
#primary-nav .menu-mlid-1709 {
    width: 21%;
    @include breakpoint($md) {
        width: 26%;
    }
    @include breakpoint($lg) {
        width: 26%;
    }
}

// Prospective Students
#primary-nav .menu-mlid-1714 {
    width: 24%;
    @include breakpoint($md) {
        width: 22%;
    }
}

// Faculty and Staff
#primary-nav .menu-mlid-1618 {
    width: 18%;
    @include breakpoint($md) {
        width: 16%;
    }
}

// Diverse Communities
#primary-nav .menu-mlid-1620 {
    width: 24%;
    @include breakpoint($md) {
        width: 22%;
    }
}


/* =Drawer Navigation
----------------------------------------------------------*/

#drawer-nav .menu-mlid-1603 .level-2 {
    @include breakpoint($sm) {
        width: 16.5%;
    }
}


/* =Demographics Panel
----------------------------------------------------------*/

.demographics {
    position: relative;
    min-height: 511px;
    background: url(../../../themes/diversity/images/home-page-canvas.jpg) no-repeat center center;
    background-size: cover;
}

.demographics__blur {
    background: url(../../../themes/diversity/images/home-page-canvas.jpg) no-repeat center center;
    position: absolute;
    z-index: 99;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 99;
    -webkit-filter: blur(5px);
    filter: blur(5px);
    @include breakpoint($xs) {
        display: none;
    }
    @include breakpoint(( min: 1280px)) {
        background-size: cover;
    }
}

.demographics__blur--mask {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include breakpoint($xs) {
        display: none;
    }
}

.center--wrap {
    width: 100%;
    padding: 60px 0;
    @include breakpoint($xs) {
        background: rgba(0, 0, 0, 0.6);
    }
    @include breakpoint($sm) {
        padding: 4% 0;
        @include center();
    }
    @include breakpoint($md) {
        padding: 6% 0;
    }
}

.stats {
    @include breakpoint($sm) {
        @include clearfix();
    }
}

.statistic {
    margin: 0 0 50px;
    text-align: center;
    line-height: 1.1;
    z-index: 100;
    @include breakpoint($sm) {
        min-height: 140px;
        margin: 0;
    }
    @include breakpoint($md) {
        min-height: none;
        margin: 0;
    }
}

.statistic__number {
    color: $wcm-white;
    font-family: $wcm-bold;
    font-size: 45px;
}

.statistic__title {
    color: $wcm-bright-orange;
    color: #f7941d;
    font-family: $wcm-bold;
    font-size: 20px;
}

.demographics__cta {
    position: absolute;
    bottom: 30px;
    width: 100%;
    z-index: 100;
    .btn--diversity {
        width: 80%;
        font-size: 14px;
        background: rgba(0, 0, 0, 0.5);
        @include breakpoint($sm) {
            width: 60%;
            font-size: 18px;
        }
        @include breakpoint($md) {
            width: 45%;
            bottom: 20px;
        }
    }
}


/* =Featured Event
----------------------------------------------------------*/

.featured-event {
    background: $wcm-bright-orange;
    position: relative;
    overflow: hidden;
}

.featured-event .row {
    display: flex;
    align-items: center;
}

.featured-event h2 {
    margin: 0;
    padding: 30px 0;
    a {
        color: $wcm-diversity-yellow;
    }
    .external-link:after {
        color: $wcm-diversity-yellow;
        padding: 5px 0 0 10px;
    }
}

.featured-event .featured-event__date {
    background: transparent;
    color: #F6A628;
    text-align: center;
    font-family: $wcm-bold;
    font-size: 30px;
    max-width: 100px;
    @include breakpoint($sm) {
        background: #F6A628;
        color: $wcm-bright-orange;
        max-width: none;
        padding: 25px 0 99999px 0;
        margin: 0 0 -99999px 0;
    }
    @include breakpoint($md) {
        padding: 15px 0 99999px 0;
        margin: 0 0 -99999px 0;
    }
    .days {
        font-size: 50px;
        line-height: 1.5;
        margin-top: -15px;
    }
}


/* =Event Teasers
----------------------------------------------------------*/

.event {
    @include clearfix();
}

.view-events .teaser__image {
    @include breakpoint($sm) {
        float: right;
        width: 25%;
        margin: 0;
    }
}

.view-events .teaser__detail {
    @include breakpoint($sm) {
        float: left;
        width: 75%;
        padding: 0 20px 0 0;
    }
}

.event__title {
    margin-bottom: 5px;
}

.event__date {
    color: #6f6f6f;
    font-size: 13px;
    font-style: italic;
    line-height: 19px;
}

.event__date .date-display-range {
    display: inline;
}

.event__date,
.event__description {
    margin-bottom: 20px;
}


/* =Landing Page Callouts
----------------------------------------------------------*/

.landing-callout {
    position: relative;
    overflow: hidden;
    @include breakpoint($xs) {
        max-width: 340px;
        margin: 0 auto;
    }
    &:after {
        content: '\e80d';
        color: $wcm-diversity-yellow;
        font-size: 30px;
        @include fontello();
        position: absolute;
        bottom: 10%;
        right: 10%;
    }
}

.landing-callout:hover:after {
    color: $wcm-dark-orange;
}

.landing-callout__mask {
    background: rgba(162, 64, 19, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.landing-callout:hover .landing-callout__mask {
    background: rgba(0, 0, 0, 0.55);
}

.landing-callout h2 {
    color: $wcm-diversity-yellow;
}

.landing-callout:hover h2 {
    color: $wcm-dark-orange;
}

.landing-callout img {
    width: 100%;
}

.landing-callout__text {
    position: absolute;
    top: 10%;
    left: 10%;
    right: 10%;
    color: $wcm-white;
    h2 {
        margin: 20px 0;
    }
}


/* =Opportunities
----------------------------------------------------------*/

.view-opportunities {
    margin: 20px 0;
}

.opportunity {
    margin: 0 0 20px;
    padding: 0 0 20px;
    border-bottom: 1px solid $wcm-border-gray;
}

.opportunity__title {
    font-size: 20px;
    font-family: $wcm-bold;
    color: $wcm-red;
    line-height: 1.3;
    padding: 0 0 20px;
}


/* =Testimonial Teasers
----------------------------------------------------------*/

.testimonial {
    margin: 0 0 40px;
    padding: 0 0 40px;
    border-bottom: 1px solid $wcm-border-gray;
    @include clearfix();
}

.view-testimonials {
    margin-top: 50px;
}

.view-testimonials .teaser__image {
    @include breakpoint($sm) {
        float: left;
        width: 25%;
        margin: 0;
    }
}

.view-testimonials .teaser__detail {
    @include breakpoint($sm) {
        float: right;
        width: 75%;
        padding: 0 20px 0 0;
    }
}

.testimonial__attribution {
    color: #6f6f6f;
    font-size: 14px;
    font-style: italic;
    line-height: 19px;
    margin: 10px 0 0;
}

.testimonial__readmore {
    margin: 10px 0 0;
    border-width: 0;
}

.testimonial .less,
.testimonial .element__toggle--open .more {
    display: none;
}

.testimonial .element__toggle--open .less {
    display: inline;
}


/* =Dean's Messages / Home
----------------------------------------------------------*/

.front .view-messages {
    @include breakpoint($sm) {
        height: 615px;
        overflow: hidden;
    }
}

.message {
    @include breakpoint($sm) {
        position: relative;
        height: 615px;
        overflow: hidden;
        transition: all .5s;
    }
}

.leader-info {
    padding: 30px 0;
    @include breakpoint($sm) {
        background: #fff;
    }
}

.message.show .leader-info {
    cursor: default;
}

.message__photo,
.message__signature {
    width: 315px;
    margin: 0 auto 20px;
}

.message__name,
.message__professional-titles {
    width: 280px;
    margin: 0 auto;
    color: #6f6f6f;
    font-size: 13px;
    font-style: italic;
    line-height: 19px;
    @include breakpoint($md) {
        width: 380px;
    }
}

.message__professional-titles .item-list ul li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.quote-info {
    background-color: rgba(0, 0, 0, 0.7);
    font-family: $wcm-regular;
    color: #dddddd;
    font-style: italic;
    line-height: 1.4;
    text-align: center;
    padding: 30px;
    @include breakpoint($sm) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        font-size: 24px;
    }
    @include breakpoint($lg) {
        font-size: 28px;
    }
}

.quote-info:after {
    @include breakpoint($sm) {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 50px 0 50px 50px;
        border-color: transparent transparent transparent #fff;
        position: absolute;
        top: 237px; // (575 - 100) / 2
        left: 0;
    }
}

.views-row-first.message .quote-info:after {
    left: auto;
    right: 0;
    border-width: 50px 50px 50px 0;
    border-color: transparent #fff transparent transparent;
}

.quote-info {
    @include breakpoint($sm) {
        opacity: 1.0;
        z-index: 100;
    }
}

.message__quote-wrap {
    @include breakpoint($sm) {
        @include center();
    }
}

.message__quote {
    margin: 0 0 20px;
}

.views-row-first.message .leader-info {
    @include breakpoint($sm) {
        float: right;
    }
}

.views-row-first.message .quote-info {
    float: left;
    @include breakpoint($sm) {
        left: 0;
    }
}


/* =Dean's Messages / Index
----------------------------------------------------------*/

.message--index {
    margin: 40px 0;
}

.message--index__quote {
    font-family: $wcm-regular;
    color: $wcm-med-gray;
    font-size: 22px;
    font-style: italic;
    padding: 0 20px 20px 0;
}

.message--index__signature {
    margin: 0 0 20px;
}

.message--index__title,
.message--index__professional-titles {
    width: 280px;
    color: #6f6f6f;
    font-size: 13px;
    font-style: italic;
    line-height: 19px;
    @include breakpoint($md) {
        width: 380px;
    }
}

.message--index__professional-titles .item-list ul li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.message--index__body {
    margin: 20px 0 0;
}

.message--index.views-row-last {
    position: relative;
    padding: 60px 0 0;
    &:before {
        display: block;
        content: "";
        position: absolute;
        left: 25%;
        top: 0;
        height: 1px;
        width: 50%;
        border-bottom: 1px solid $wcm-border-gray;
    }
}


/* =Custom Callout // Home
----------------------------------------------------------*/

.diversity-callout {
    position: relative;
    background: $wcm-diversity-yellow;
    padding: 20px 0;
    color: $wcm-white;
    @include breakpoint($sm) {
        display: flex;
        align-items: center;
    }
}

.diversity-callout__image {
    width: 80%;
    margin: 0 0 20px;
    @include breakpoint($sm) {
        width: auto;
        margin: 0;
    }
    img {
        margin-bottom: 0 !important;
    }
}

.diversity-callout__description {
    @include breakpoint($md) {
        margin: 10% 0 0;
    }
}

.diversity-callout__description h3 {
    margin-top: 0;
}

.diversity-callout__description h3 a {
    font-size: 30px;
    color: $wcm-white;
}

.diversity-callout__description p {
    margin-bottom: 0;
    @include breakpoint($md) {
        font-size: 20px;
    }
}


/* =Diversity News // Home
----------------------------------------------------------*/

.diversity-news-home {
    background: $wcm-dark-orange;
    // padding: 30px 0; diversity button commented out in .tpl neccessitates this change for the footer - RITM0546054
    @include breakpoint($sm) {
        //padding: 60px 0;
    }
}

.diversity-news {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $wcm-border-gray;
}

.diversity-news:last-of-type {
    border: none;
}

.diversity-news__title {
    font-size: 20px;
    line-height: 1.3;
    font-family: $wcm-bold;
    color: $wcm-red;
    margin: 0 0 5px;
}

.diversity-news-home .diversity-news__title {
    font-size: 20px;
    line-height: 1.3;
    font-family: $wcm-bold;
    color: $wcm-white;
    margin: 0 0 5px;
    @include breakpoint($sm) {
        font-size: 28px;
    }
    h3 {
        font-size: 30px;
    }
}

.diversity-news-home .diversity-news__description {
    font-size: 16px;
    margin: 0 0 20px 0;
    color: $wcm-white;
    @include breakpoint($sm) {
        font-size: 20px;
        margin: 0 0 30px 0;
    }
}

.diversity-news-home .diversity-news__readmore a {
    color: $wcm-white;
    font-size: 16px;
}

.diversity-news__text {
    text-align: left;
    padding-top: 20px;
}


/* =Spotlight // Home
----------------------------------------------------------*/

.front .view-testimonials {
    margin-top: 0;
}

.front .spotlight,
.front .spotlight-testimonial {
    position: relative;
    background: url(/sites/all/themes/diversity/images/home-diversity-testimonials.jpg);
    background-size: cover;
    padding: 60px 0;
    @include clearfix();
}

.spotlight__content {
    @include breakpoint($sm) {
        display: flex;
        align-items: center;
    }
}

.spotlight__description {
    color: $wcm-white;
    font-size: 13px;
    font-style: italic;
    line-height: 19px;
    @include breakpoint($sm) {
        float: right;
        font-size: 14px;
        text-align: right;
    }
}

.spotlight-testimonial .spotlight__description {
    position: static;
    margin: 0 auto;
    padding: 20px 0;
    @include breakpoint($sm) {
        background: none;
        padding: 0;
        margin: 0 0 0 auto;
    }
}

.spotlight-testimonial .spotlight__photo img {
    display: none;
    @include breakpoint($sm) {
        display: block;
    }
}

.spotlight-testimonial .spotlight__headshot {
    max-width: 200px;
    height: auto;
    margin: 0 auto 10px;
    @include breakpoint($sm) {
        margin: 0 0 10px;
        float: right;
    }
    @include breakpoint($lg) {
        max-width: none;
    }
}

.spotlight-testimonial .spotlight__name,
.spotlight-testimonial .spotlight__year-or-major {
    text-align: center;
    @include breakpoint($sm) {
        clear: right;
        float: right;
        text-align: right;
    }
}

.spotlight__action {
    padding: 20px;
    text-align: center;
    @include breakpoint($sm) {
        float: left;
        background-color: transparent;
        text-align: left;
        padding: 0;
    }
}

.spotlight__quote {
    max-width: 500px;
    font-family: $wcm-regular;
    color: $wcm-white;
    font-style: italic;
    font-size: 18px;
    line-height: 1.4;
    margin: 0 auto;
    @include breakpoint($sm) {
        font-size: 24px;
        padding: 0;
    }
    @include breakpoint($md) {
        font-size: 30px;
        line-height: 1.4;
    }
}

.spotlight__action .btn--diversity {
    margin: 20px auto 20px;
    @include breakpoint($sm) {
        margin: 30px 0;
    }
}


/* =Spotlight // Index
----------------------------------------------------------*/

.spotlight--index__photo {
    margin: 0 0 20px;
}

.spotlight--index__description {
    margin: 20px 0 0;
}

.spotlight--index__year-or-major,
.spotlight--index__school-or-position {
    font-family: $wcm-bold;
    color: $wcm-red;
    font-size: 16px;
    line-height: 1.4;
    margin: 0 0 10px;
}

.spotlight--index__quote {
    font-family: $wcm-regular;
    color: $wcm-med-gray;
    font-size: 18px;
    font-style: italic;
    line-height: 1.4;
    margin: 20px 0 0;
    padding: 20px 0 0;
    border-top: 1px solid $wcm-diversity-yellow;
}


/* =Staff Directory
----------------------------------------------------------*/

.view-profiles .gridcol {
    margin: 0 0 20px;
}

.staff-profile__photo {
    max-height: 192px;
    overflow: hidden;
    margin: 0 0 10px;
}

.staff-profile__professional-title,
.staff-profile__phone {
    color: #6f6f6f;
    font-size: 14px;
    font-style: italic;
    line-height: 19px;
}

.staff-member__name {
    color: $wcm-red;
    font-family: $wcm-bold;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    padding: 0 0 5px;
}

.staff-profile__professional-title .item-list ul,
.staff-profile__professional-title .item-list ul li {
    list-style-type: none;
    margin: 0;
    padding: 0 0 5px;
}

.staff-profile__phone {
    font-style: normal;
    margin: 0 0 10px;
}

.staff-profile__links ul li {
    list-style-type: none;
    margin: 0;
    padding: 0 0 5px;
}


/* =FAQ
----------------------------------------------------------*/

.faq--featured {
    background: $wcm-dark-gray;
    margin: 0 0 30px;
    padding: 30px;
}

.faq--featured__question {
    font-family: $wcm-bold;
    color: $wcm-diversity-yellow;
    font-size: 24px;
    line-height: 1.5;
}

.faq--featured__answer {
    color: $wcm-white;
    font-size: 22px;
    line-height: 1.6;
    span {
        font-family: $wcm-bold;
        color: $wcm-diversity-yellow;
    }
}

.view-faq .view-filters {
    margin: 0 0 30px;
}


/* =Communities
----------------------------------------------------------*/

.community__title.element__toggle {
    font-size: 20px;
    line-height: 28px;
    padding: 20px 55px 20px 10px;
    &:after {
        top: 20px;
    }
}


/* =Workshop Participants
----------------------------------------------------------*/

.workshop-participants h3 {
    margin-bottom: 10px;
}

.workshop-participants p {
    column-count: 2;
}


/* =Child Nav Style
(usually shown on First-Level Pages with Children)
----------------------------------------------------------*/

.pane-menu-block-active-third-level-nav {
    display: none;
    @include breakpoint($sm) {
        display: block;
        margin: 20px 0;
    }
}

.pane-menu-block-active-third-level-nav ul {
    margin: 0;
    padding: 0;
}

.pane-menu-block-active-third-level-nav li {
    list-style: none;
}

.pane-menu-block-active-third-level-nav a {
    display: block;
    position: relative;
    background-color: $wcm-white;
    color: $wcm-dark-orange;
    border-bottom: 1px solid $wcm-border-gray;
    font-size: 13px;
    line-height: 50px;
    text-align: right;
    padding: 0 60px 0 0;
    @include transition(background-color .25s);
    &:after {
        content: '\e802';
        position: absolute;
        right: 10px;
        top: 5px;
        width: 40px;
        height: 40px;
        color: $wcm-border-gray;
        border: 1px solid $wcm-border-gray;
        border-radius: 50%;
        font-size: 17px;
        text-align: center;
        @include fontello();
        line-height: 42px;
        @include transition(background-color .25s);
    }
    &:hover {
        color: $wcm-bright-orange;
        background-color: #f7f7f7;
        text-decoration: none;
        border-width: 0 0 1px;
    }
    &:hover:after {
        border: 1px solid $wcm-bright-orange;
        color: $wcm-bright-orange;
    }
}


/* =Forms/Buttons
----------------------------------------------------------*/

.btn--diversity {
    border-color: $wcm-diversity-yellow;
    color: $wcm-diversity-yellow;
    font-style: normal;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    .open>&.dropdown-toggle {
        color: $wcm-white;
        background-color: $wcm-bright-orange;
        border-color: $wcm-diversity-yellow;
    }
}

.category .btn--wcm {
    margin: 0;
}

.view-filters .form-select {
    max-width: none;
    min-width: 200px;
    @include breakpoint($sm) {
        min-width: 300px;
    }
}


/* =Footer
----------------------------------------------------------*/

.front footer {
    padding-top: 0;
}

.footer-nav {
    @include breakpoint($md) {
        margin: 0 0 20px;
    }
}

.footer-nav .level-1 {
    @include breakpoint($md) {
        width: 20%;
        float: left;
        padding: 0 10px 5px;
    }
}


/* =Misc
----------------------------------------------------------*/

.category {
    margin: 0 0 30px;
}

.row.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.row.no-gutters>[class^="col-"],
.row.no-gutters>[class*=" col-"] {
    padding-right: 0;
    padding-left: 0;
}


/* =News Story
----------------------------------------------------------*/


/* =News Story Nodes
----------------------------------------------------------*/

.pane-node-field-story-deck,
.pane-node-field-photo-story-deck {
    font-size: 20px;
    color: $wcm-med-gray;
    line-height: 1.3;
    padding-bottom: 10px;
}

.pane-node-field-story-post-date,
.related-news h3 {
    text-transform: uppercase;
    color: $wcm-med-gray;
    font-family: $wcm-bold;
    font-size: 13px;
    letter-spacing: 2px;
    padding-bottom: 10px;
}

.pane-node-field-story-featured-image {
    margin: 25px 0;
}

.related-news {
    margin: 40px 0;
    padding: 10px 20px 20px;
    border: 1px solid $wcm-bright-orange;
}


/* =News Story Index
----------------------------------------------------------*/

.pane-news-stories-panel-pane-1 h3 {
    margin-top: 0;
}

.select2-results__option:first-child {
    font-style: italic;
    border-bottom: 2px solid #ddd;
}

.news-story {
    max-width: 640px;
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid $wcm-border-gray;
    @include breakpoint($md) {
        max-width: none;
    }
}

.news-story:last-child {
    @include breakpoint($md) {
        border-bottom: none;
    }
}

.news-story__title {
    @include breakpoint($md) {
        margin-top: 0;
    }
}

.news-story__date {
    text-transform: uppercase;
    color: $wcm-med-gray;
    font-family: $wcm-bold;
    font-size: 13px;
    letter-spacing: 2px;
    margin: 5px 0 20px;
}

.news-story__read-more {
    padding-top: 7.5px;
    a:after {
        content: '\e80d';
        color: $wcm-bright-orange;
        padding: 5px 0 0 10px;
        font-size: 60%;
        vertical-align: top;
        @include fontello();
        text-decoration: none;
        line-height: 1.5;
    }
}

.news-story__tags,
.story-tags {
    margin: 34px 0 0;
    font-size: 12px;
    background: url(../images/tag.png) left center no-repeat;
    background-size: 15px 15px;
    padding-left: 22px;
}

.news-story__type {
    font-size: 12px;
    text-transform: uppercase;
    font-family: $wcm-bold;
    padding: 5px 10px 0;
    margin-bottom: 3px;
    background: $wcm-dark-orange;
    max-width: 640px;
    a {
        color: $wcm-white;
        &:hover {
            color: $wcm-white;
            text-decoration: none;
        }
    }
}

.news-story__type--1 {
    background: $wcm-bright-orange;
}

.news-story__type--4 {
    background: $wcm-yellow;
}


/* =Index Filters
----------------------------------------------------------*/

.view-news-stories .views-exposed-form {
    margin: 10px 0 60px;
}

.view-news-stories .views-exposed-form label {
    display: none;
}


/* =Featured News Stories Tiles
----------------------------------------------------------*/

.featured-news-stories {
    margin: 0 0 10px;
    @include clearfix();
}

.featured-news-story {
    overflow: hidden;
    position: relative;
    max-width: 640px;
    margin: 0 0 3%;
    padding: 0;
    a {
        color: #fff;
    }
    @include breakpoint($sm) {
        &:hover {
            .featured-news-story__image img {
                transform: scale(1.25);
            }
            .featured-news-story__teaser {
                display: block;
            }
        }
    }
    @include breakpoint($md) {
        max-width: none;
        width: 48%;
        float: left;
        margin-right: 2px;
        margin-left: 20px;
    }
    @include breakpoint($sm) {
        width: 47%;
        max-width: none;
        float: left;
        margin-left: 20px;
    }
}

.featured-news-story.views-row-odd {
    @include breakpoint($md) {
        margin-right: 3%;
    }
}

.mask {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 97%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
}

.featured-news-story__image {
    overflow: hidden;
    width: 97%;
}

.featured-news-story__image img {
    transition: all 0.3s ease-in-out;
}

.featured-news-story__title {
    color: #fff;
}

.featured-news-story .news-story__date {
    color: #fff;
}

.featured-news-story .story-details {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0 30px;
    z-index: 100;
}

.featured-news-story__teaser {
    display: none;
    width: 88%
}

.diversity-news__description {
    height: auto;
    min-height: 200px;
    // height:200px;
    // @include breakpoint($xs) {
    //   height:100% !important;
    //   }
}

.featured-image-index {
    float: left;
    padding-right: 20px;
}

.latest-diversity-news-item {
    text-align: center;
}

.latest-diversity-news-item a {
    color: #ffffff;
}


/* ------- Calendar Styles ----------- */

.date-prev {
    margin-top: 24px !important;
    margin-right: 23px !important;
    background: none !important;
}

.date-next {
    margin-top: 24px !important;
    background: none !important;
}


/* ----- CTools ------ */

.ctools-collapsible-processed .ctools-collapsible-handle h2 {
    position: relative;
    font-size: 18px;
    line-height: 20px;
    color: #cf4520;
    border: 1px solid #ddd;
    border-width: 3px 0 0;
    cursor: pointer;
    margin: 0;
    padding: 15px 55px 15px 10px;
    font-weight: 600;
}

.ctools-collapsible-processed .ctools-collapsible-handle h2:after {
    content: '-';
    width: 50px;
    display: block;
    position: absolute;
    top: 15px;
    right: 0;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    border-left: 3px solid #ddd;
}

.ctools-collapsible-processed.ctools-collapsed .ctools-collapsible-handle h2:after {
    content: '+';
}

.ctools-collapsible-container .ctools-toggle {
    display: none;
}

.fancybox-nav {
    width: 10%;
    height: 50%;
    top: 25%;
}

a.fancybox-nav {
    border: 0;
}