/**
 * Styles related to Photo Stories
 *
 */
.pane-gallery-panel-pane-2 {
  .views-row {
    margin-bottom: 30px;

    &.views-row-odd {
      clear: left;
    }
  }
}

.photo-story {
  position: relative;
}

.pane-node-field-gallery-videos, .pane-node-field-gallery-images {
  margin-top: 30px;

  .container {
    position: relative;

    .cycle-slide {
      width: 100%;

      img {
        margin: 0 auto;
        display: block;
      }

      .galpag a > img {
        height: auto !important;
      }
    }
  }
}
.pane-node-field-gallery-images, .paragraphs-items-field-gallery-videos {
  clear: both;
  border-bottom: 1px solid $wcm-border-gray;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.paragraphs-items-field-gallery-images .cycle-slideshow, .paragraphs-items-field-gallery-videos .cycle-slideshow {
  background: none;

  .paragraphs-item-gallery-images, .paragraphs-item-gallery-videos {
    width: 100%;
  }
}

.paragraphs-items-field-gallery-images .thumbnail-controller, .paragraphs-items-field-gallery-videos .thumbnail-controller {
  // background: #f7f7f7;
  // padding: 30px;
  // overflow: hidden;
  @include clearfix();
}

/*.paragraphs-items-field-gallery-images #thumbnail-pager a, .paragraphs-items-field-gallery-videos #thumbnail-pager-video a {
  display: inline-block;
  margin: 0 15px 10px 0;

  &:last-child {
    margin-right: 0;
    padding-right: 0;
  }
}*/

.photo-gallery__image {
  position: relative;
  text-align: center;

  img {
    margin-bottom: 0 !important;
  }
}

.photo-gallery__caption {
  margin: 20px 0;
}

p.galcapt {
  text-align: center;
  // margin-top: 10px;
  margin: 20px 0;
}

.thumbnail-controller {
  background: #f7f7f7;
  padding: 30px;

  .thumbnail-controller__wrap {

    .thumbnail-controller__wrap-inner {
      display: flex;
      justify-content: center;
      flex-direction: row;

      @include breakpoint($md) {
        justify-content: space-between;
      }

      .thumbnail-controller__pager {
        &:after {
          content: '\e802';
          color: $wcm-bright-orange;
          font-size: 24px;
          vertical-align: middle;
          @include fontello();
          @include v-center;
          text-decoration: none;
          line-height: 1.5;
        }

        &.thumbnail-previous, &.thumbnail-previous-video {
          flex: 0 1;
          &:after {
            content: '\e810';
            @include breakpoint($md) {
              padding: 3px 10px 0 0;
            }
          }
        }

        &.thumbnail-next, &.thumbnail-next-video {
          flex: 0 1;
          &:after {
            content: '\e802';
            @include breakpoint($md) {
              padding: 3px 0 0 10px;
            }
          }
        }
      }

      #thumbnail-pager, #thumbnail-pager-video {
        flex: 1 0;
        display: none;
        @include breakpoint($md) {
          display: block;
        }

        .cycle-slide {
          vertical-align: top;
          span {
            display: inline-block;
            @include breakpoint($md) {
              margin: 0 15px 0 0;
            }
          }

          img {
            cursor: pointer;
            width: 100%;
            height: auto;
            display: block;
          }
          &.cycle-slide-active img {
            border: 1px solid $wcm-red;
          }
        }
      }

      #mobile-slide-counter, #mobile-slide-counter-video {
        flex: 0 1 30%;
        text-align: center;
        padding: 5px 20px;
        @include breakpoint($md) {
          display: none !important;
        }
      }
    }
  }
}

/*.thumbnail-controller__wrap {
  float: left;
  position: relative;
  left: 50%;
}

.thumbnail-controller__wrap-inner {
  position: relative;
  left: -50%;
}*/

/*.thumbnail-controller__pager {
  display: block;
  float: left;
  padding: 0 10px;
  &:after {
    content: '\e802';
    color: $wcm-bright-orange;
    font-size: 24px;
    vertical-align: top;
    @include fontello();
    text-decoration: none;
    line-height: 1.5;
  }
}*/
/*
.thumbnail-controller__pager.previous, .thumbnail-controller__pager.previous-video {
  &:after {
    content: '\e810';
    @include breakpoint($md) {
      padding: 3px 10px 0 0;
    }
  }
}

.thumbnail-controller__pager.next, .thumbnail-controller__pager.next-video {
  float: right;
  &:after {
    content: '\e802';
    @include breakpoint($md) {
      padding: 3px 0 0 10px;
    }
  }
}

#thumbnail-pager, #thumbnail-pager-video {
  display: none;
  @include breakpoint($md) {
    float: left;
    display: block;
  }
}

#thumbnail-pager-video a {
  border: none;
}
#thumbnail-pager-video a > img {
  border: 3px solid $wcm-border-gray;
  opacity: .5;
}

#thumbnail-pager-video a > img:hover, #thumbnail-pager-video .cycle-pager-active > img {
  border: 3px solid $wcm-bright-orange;
  opacity: 1;
}*/

/*#mobile-slide-counter, #mobile-slide-counter-video {
  float: left;
  padding: 3px 20px;
  @include breakpoint($md) {
    display: none !important;
  }
}*/

/* =Previous / Next Arrow Overlays
----------------------------------------------------------------------------------*/

.paragraphs-items-field-gallery-images, .paragraphs-items-field-gallery-videos {
  .cycle-prev, .cycle-next {
    display: none;
    @include breakpoint($sm) {
      position: absolute;
      top: 0;
      opacity: 0;
      filter: alpha(opacity=0);
      width: 10%;
      z-index: 1002;
      height: 100%;
      cursor: pointer;
    }
    @include breakpoint($md) {
      display: block;
    }
  }

  .cycle-prev {
    left: 20px;
    background: url("../images/carousel-prev.png") 35% 50% no-repeat;
    @include breakpoint($lg) {
      background-position: 35% 50%;
    }
  }

  .cycle-next {
    right: 20px;
    background: url("../images/carousel-next.png") 65% 50% no-repeat;
    @include breakpoint($lg) {
      background-position: 35% 50%;
    }
  }

  .cycle-prev:hover,
  .cycle-next:hover {
    opacity: .7;
    filter: alpha(opacity=70);
  }
}


